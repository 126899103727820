<template>
  <CreateUpdateTemplate
    :customClass="'equipment-create'"
    v-if="getPermission('equipment:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">Create new Equipment</h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate"
        color="cyan"
      >
        Save Equipment
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="equipmentForm"
        v-model="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate"
      >
        <v-container fluid>
          <v-row>
            <v-col
              class="custom-content-container pt-0"
              cols="12"
              md="9"
              sm="9"
            >
              <!-- <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow"
                style="max-height: 70vh; position: relative"
              > -->
              <v-row>
                <v-col md="10" offset-md="1" class="pb-0">
                  <v-checkbox
                    class="mt-0 width-100"
                    color="cyan"
                    :disabled="pageLoading"
                    v-model="equipmentCreate.show_name"
                  >
                    <template v-slot:label>
                      <label class="custom-form-label m-0 width-100"
                        >Show name, qty, price in Document</label
                      >
                    </template>
                  </v-checkbox>
                  <v-autocomplete
                    v-model="equipmentCreate.product"
                    :items="serverItemList"
                    :search-input.sync="serverSearch"
                    dense
                    filled
                    flat
                    ref="search-item"
                    :loading="serverSearchLoading"
                    label="Start typing to Search..."
                    placeholder="Start typing to Search..."
                    solo
                    no-filter
                    item-color="cyan"
                    color="cyan"
                    :disabled="pageLoading"
                    item-text="name"
                    item-value="id"
                    class="remove-border-radius hide-border-bottom"
                    return-object
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title v-if="serverSearchLoading">
                          Getting results...
                          <v-progress-linear
                            color="cyan"
                            indeterminate
                            rounded
                            height="6"
                          ></v-progress-linear>
                        </v-list-item-title>
                        <v-list-item-title v-else>
                          No Product Found
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-avatar
                        :color="
                          lodash.isEmpty(item.primary_image) ? 'cyan' : 'white'
                        "
                        size="70"
                        class="headline font-weight-light white--text text-uppercase"
                      >
                        <template
                          v-if="lodash.isEmpty(item.primary_image) === false"
                          ><v-img
                            :src="$assetAPIURL(item.primary_image)"
                          ></v-img
                        ></template>
                        <template v-else>{{ item.name.charAt(0) }}</template>
                      </v-list-item-avatar>
                      <v-list-item-content class="max-width-350px">
                        <v-list-item-title
                          class="font-size-16 text-truncate pb-1 text-capitalize"
                          v-text="item.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-if="item.description"
                          class="font-size-14 text-truncate pb-1"
                          v-text="item.description"
                        ></v-list-item-subtitle>
                        <template>
                          <v-list-item-subtitle class="font-size-14 pb-1">
                            <span class="font-weight-500">Serial Number: </span>
                            <template v-if="item.serial_number">
                              {{ item.serial_number }}</template
                            >
                            <template v-else>No Serial Number</template>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="font-size-14 pb-1">
                            <span class="font-weight-500">Part Number: </span>
                            <template v-if="item.part_number">
                              {{ item.part_number }}</template
                            >
                            <template v-else>No Part Number</template>
                          </v-list-item-subtitle>
                          <template v-if="false">
                            <v-list-item-subtitle class="font-size-14 pb-1"
                              ><span class="font-weight-500"
                                >Selling Cost:</span
                              >
                              {{
                                formatNumber(item.selling_cost)
                              }}</v-list-item-subtitle
                            >
                            <v-list-item-subtitle class="font-size-14"
                              ><span class="font-weight-500"
                                >Available Stock:</span
                              >
                              {{
                                accountingUnFormat(item.available_stock)
                              }}</v-list-item-subtitle
                            >
                          </template>
                        </template>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                  <v-text-field
                    dense
                    filled
                    label="Name"
                    solo
                    flat
                    color="cyan"
                    v-model="equipmentCreate.name"
                    :rules="[
                      validateRules.required(equipmentCreate.name, 'Name'),
                      validateRules.minLength(equipmentCreate.name, 'Name', 1),
                      validateRules.maxLength(
                        equipmentCreate.name,
                        'Name',
                        100
                      ),
                    ]"
                  ></v-text-field>
                  <v-textarea
                    dense
                    filled
                    label="Description"
                    solo
                    flat
                    row-height="20"
                    auto-grow
                    color="cyan"
                    v-model="equipmentCreate.description"
                    :rules="[
                      validateRules.minLength(
                        equipmentCreate.description,
                        'Description',
                        1
                      ),
                      validateRules.maxLength(
                        equipmentCreate.description,
                        'Description',
                        1024
                      ),
                    ]"
                  ></v-textarea>
                  <div class="d-flex">
                    <v-text-field
                      dense
                      filled
                      color="cyan"
                      label="Serial Number"
                      solo
                      flat
                      :rules="[
                        validateRules.minLength(
                          equipmentCreate.serial_number,
                          'Serial Number',
                          1
                        ),
                        validateRules.maxLength(
                          equipmentCreate.serial_number,
                          'Serial Number',
                          100
                        ),
                      ]"
                      class="pr-2 width-100"
                      v-model="equipmentCreate.serial_number"
                    ></v-text-field>
                    <v-text-field
                      dense
                      filled
                      color="cyan"
                      label="Part Number"
                      solo
                      flat
                      :rules="[
                        validateRules.minLength(
                          equipmentCreate.part_number,
                          'Part Number',
                          1
                        ),
                        validateRules.maxLength(
                          equipmentCreate.part_number,
                          'Part Number',
                          100
                        ),
                      ]"
                      class="pl-2 width-100"
                      v-model="equipmentCreate.part_number"
                    ></v-text-field>
                  </div>
                  <div class="d-flex">
                    <v-text-field
                      dense
                      filled
                      color="cyan"
                      label="Reference"
                      solo
                      flat
                      :rules="[
                        validateRules.minLength(
                          equipmentCreate.reference,
                          'Reference',
                          1
                        ),
                        validateRules.maxLength(
                          equipmentCreate.reference,
                          'Reference',
                          100
                        ),
                      ]"
                      class="pr-2 width-100"
                      v-model="equipmentCreate.reference"
                    ></v-text-field>
                    <v-text-field
                      dense
                      filled
                      color="cyan"
                      label="Barcode"
                      solo
                      flat
                      class="pl-2 width-100"
                      readonly
                      v-model="equipmentCreate.barcode"
                    ></v-text-field>
                  </div>
                  <div class="d-flex">
                    <v-autocomplete
                      :items="supplierList"
                      dense
                      filled
                      color="cyan"
                      item-color="cyan"
                      label="Supplier"
                      solo
                      flat
                      item-text="display_name"
                      item-value="id"
                      v-model="equipmentCreate.supplier"
                      class="pr-2 width-100"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Supplier(s) Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <v-autocomplete
                      :items="brandList"
                      dense
                      filled
                      color="cyan"
                      item-color="cyan"
                      label="Brand"
                      solo
                      flat
                      append-outer-icon="mdi-cog"
                      v-on:click:append-outer="manageBrandDialog = true"
                      item-text="text"
                      item-value="id"
                      class="pl-2 width-100"
                      v-model="equipmentCreate.brand"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Brand(s) Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </div>
                  <div class="d-flex">
                    <v-autocomplete
                      dense
                      filled
                      color="cyan"
                      item-color="cyan"
                      :items="manufacturerList"
                      label="Manufacturer"
                      solo
                      flat
                      append-outer-icon="mdi-cog"
                      v-on:click:append-outer="manageManufacturerDialog = true"
                      item-text="text"
                      item-value="id"
                      class="pr-2 width-100"
                      v-model="equipmentCreate.manufacturer"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Manufacturer(s) Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <v-autocomplete
                      dense
                      filled
                      color="cyan"
                      item-color="cyan"
                      :items="categoryList"
                      label="Category"
                      solo
                      flat
                      append-outer-icon="mdi-cog"
                      v-on:click:append-outer="manageCategoryDialog = true"
                      item-text="text"
                      item-value="id"
                      class="pl-2 width-100"
                      v-model="equipmentCreate.category"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Category(s) Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </div>
                  <div class="d-flex">
                    <label class="custom-form-label">Tax Preference</label>
                    <v-radio-group
                      class="pt-0 mt-0 mb-2"
                      dense
                      row
                      color="cyan"
                      v-model="equipmentCreate.taxable"
                    >
                      <v-radio
                        label="Taxable"
                        color="cyan"
                        :value="true"
                      ></v-radio>
                      <v-radio
                        label="Non-Taxable"
                        color="cyan"
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                  <div class="d-flex">
                    <v-text-field
                      dense
                      filled
                      color="cyan"
                      label="Charges"
                      solo
                      flat
                      :rules="
                        equipmentCreate.product_type != 'goods'
                          ? [
                              validateRules.required(
                                equipmentCreate.charges,
                                'Charges'
                              ),
                            ]
                          : []
                      "
                      class="pr-2 width-100"
                      type="number"
                      min="0"
                      max="9999"
                      prepend-inner-icon="mdi-currency-usd"
                      v-model.number.lazy="equipmentCreate.charges"
                      v-on:keypress="
                        limitDecimal($event, equipmentCreate.charges)
                      "
                    ></v-text-field>
                    <v-autocomplete
                      v-if="equipmentCreate.taxable"
                      dense
                      color="cyan"
                      item-color="cyan"
                      :items="taxList"
                      filled
                      label="Tax"
                      solo
                      flat
                      item-text="name"
                      item-value="id"
                      :rules="[
                        validateRules.required(equipmentCreate.tax, 'Tax'),
                      ]"
                      v-model="equipmentCreate.tax"
                      class="pl-2 width-100"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Tax(s) Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <v-autocomplete
                      v-else
                      :items="taxExemptionList"
                      dense
                      filled
                      color="cyan"
                      item-color="cyan"
                      label="Tax Exemption"
                      solo
                      flat
                      item-text="name"
                      item-value="id"
                      :rules="[
                        validateRules.required(
                          equipmentCreate.tax_exemption,
                          'Tax Exemption'
                        ),
                      ]"
                      v-model="equipmentCreate.tax_exemption"
                      class="pl-2 width-100"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Tax Exemption(s) Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </div>
                  <div v-if="getPermission('warranty:create')">
                    <v-checkbox
                      class="mt-0 width-100"
                      color="cyan"
                      v-model="warrantyActive"
                    >
                      <template v-slot:label>
                        <label class="custom-form-label m-0 width-100"
                          >Warranty</label
                        >
                      </template>
                    </v-checkbox>
                    <template v-if="warrantyActive">
                      <template
                        v-for="(warranty, index) in equipmentCreate.warranty"
                      >
                        <div class="d-flex" :key="index">
                          <v-text-field
                            dense
                            v-mask="'###'"
                            filled
                            color="cyan"
                            label="Duration"
                            solo
                            flat
                            class="pr-2 width-100"
                            v-model="warranty.value"
                          ></v-text-field>
                          <v-select
                            :items="warrantyDurationTypes"
                            dense
                            filled
                            color="cyan"
                            hide-details
                            solo
                            flat
                            item-color="cyan"
                            class="pl-2 pr-2 width-100"
                            v-model="warranty.field"
                            label="Type"
                            item-text="text"
                            item-value="value"
                          ></v-select>
                          <div class="pl-2 width-50">
                            <template v-if="index > 0">
                              <v-btn
                                class="mx-2"
                                color="danger"
                                dark
                                fab
                                small
                                v-on:click="removeWarranty(index, warranty.id)"
                              >
                                <v-icon dark> mdi-minus</v-icon>
                              </v-btn>
                            </template>
                            <template v-else>
                              <v-btn
                                class="mx-2"
                                color="cyan"
                                dark
                                fab
                                small
                                v-on:click="pushWarranty"
                              >
                                <v-icon dark> mdi-plus</v-icon>
                              </v-btn>
                            </template>
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>
                  <div v-if="getPermission('attachment:create')">
                    <label class="custom-form-label width-100"
                      >Images
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mx-2" v-bind="attrs" v-on="on"
                            >mdi-progress-question</v-icon
                          >
                        </template>
                        <span>More than 5 images are not allowed</span>
                      </v-tooltip>
                    </label>
                    <div>
                      <FileTemplate
                        allowUpload
                        isMinDisplay
                        :attachments.sync="attachment"
                        v-on:file:updated="updateAttachment"
                      ></FileTemplate>
                    </div>
                  </div>
                  <div class="py-4">
                    <label
                      class="custom-form-label custom-border-bottom width-100"
                      >Service Form Fields</label
                    >
                  </div>
                  <div class="d-block">
                    <v-select
                      v-model="equipmentCreate.service_form"
                      dense
                      filled
                      color="cyan"
                      solo
                      flat
                      :items="serviceFormList"
                      item-text="label"
                      item-value="value"
                      item-color="cyan"
                      class="pr-2 width-50"
                      label="Service Form"
                      placeholder="Select Service Form"
                    ></v-select>
                  </div>
                  <div class="d-flex">
                    <v-text-field
                      dense
                      filled
                      color="cyan"
                      label="Frame Serial Number"
                      solo
                      flat
                      :rules="[
                        validateRules.maxLength(
                          equipmentCreate.pm_one_serial_number,
                          'Frame Serial Number',
                          100
                        ),
                      ]"
                      class="pr-2 width-100"
                      v-model="equipmentCreate.pm_one_serial_number"
                    ></v-text-field>
                    <v-text-field
                      dense
                      filled
                      color="cyan"
                      label="Module Serial Number"
                      solo
                      flat
                      :rules="[
                        validateRules.maxLength(
                          equipmentCreate.md_one_serial_number,
                          'Module Serial Number',
                          100
                        ),
                      ]"
                      class="pl-2 width-100"
                      v-model="equipmentCreate.md_one_serial_number"
                    ></v-text-field>
                  </div>
                  <div class="d-flex">
                    <v-text-field
                      dense
                      filled
                      color="cyan"
                      label="Control Version"
                      solo
                      flat
                      :rules="[
                        validateRules.maxLength(
                          equipmentCreate.control_version,
                          'Control Version',
                          100
                        ),
                      ]"
                      class="pr-2 width-100"
                      v-model="equipmentCreate.control_version"
                    ></v-text-field>
                    <v-text-field
                      dense
                      filled
                      color="cyan"
                      label="System Version"
                      solo
                      flat
                      :rules="[
                        validateRules.maxLength(
                          equipmentCreate.system_version,
                          'System Version',
                          100
                        ),
                      ]"
                      class="pl-2 width-100"
                      v-model="equipmentCreate.system_version"
                    ></v-text-field>
                  </div>
                  <div v-if="false" class="d-flex">
                    <v-text-field
                      dense
                      filled
                      color="cyan"
                      label="Rectifier DSP Firmware"
                      solo
                      flat
                      :rules="[
                        validateRules.maxLength(
                          equipmentCreate.rectifier_dsp_firmware,
                          'Rectifier DSP Firmware',
                          100
                        ),
                      ]"
                      class="pr-2 width-100"
                      v-model="equipmentCreate.rectifier_dsp_firmware"
                    ></v-text-field>
                    <v-text-field
                      dense
                      filled
                      color="cyan"
                      label="Inverter DSP Firmware"
                      solo
                      flat
                      :rules="[
                        validateRules.maxLength(
                          equipmentCreate.inverter_dsp_firmware,
                          'Inverter DSP Firmware',
                          100
                        ),
                      ]"
                      class="pl-2 width-100"
                      v-model="equipmentCreate.inverter_dsp_firmware"
                    ></v-text-field>
                  </div>
                  <div v-if="false" class="d-flex">
                    <v-text-field
                      dense
                      filled
                      color="cyan"
                      label="Module EPROM Version"
                      solo
                      flat
                      :rules="[
                        validateRules.maxLength(
                          equipmentCreate.module_eprom_version,
                          'Module EPROM Version',
                          100
                        ),
                      ]"
                      class="pr-2 width-100"
                      v-model="equipmentCreate.module_eprom_version"
                    ></v-text-field>
                    <v-text-field
                      dense
                      filled
                      color="cyan"
                      label="Hardware Version"
                      solo
                      flat
                      :rules="[
                        validateRules.maxLength(
                          equipmentCreate.hardware_version,
                          'Hardware Version',
                          100
                        ),
                      ]"
                      class="pl-2 width-100"
                      v-model="equipmentCreate.hardware_version"
                    ></v-text-field>
                  </div>
                  <div class="d-flex">
                    <v-text-field
                      dense
                      filled
                      color="cyan"
                      label="Battery Bank"
                      solo
                      flat
                      :rules="[
                        validateRules.maxLength(
                          equipmentCreate.battery_bank,
                          'Battery Bank',
                          100
                        ),
                      ]"
                      class="pr-2 width-100"
                      v-model="equipmentCreate.battery_bank"
                    ></v-text-field>
                    <v-text-field
                      dense
                      filled
                      color="cyan"
                      label="Battery Model"
                      solo
                      flat
                      :rules="[
                        validateRules.maxLength(
                          equipmentCreate.battery_model,
                          'Battery Model',
                          100
                        ),
                      ]"
                      class="pl-2 width-100"
                      v-model="equipmentCreate.battery_model"
                    ></v-text-field>
                  </div>
                  <div class="d-flex">
                    <v-text-field
                      dense
                      filled
                      color="cyan"
                      label="No. Of Block"
                      solo
                      flat
                      :rules="[
                        validateRules.maxLength(
                          equipmentCreate.no_of_block,
                          'No. Of Block',
                          100
                        ),
                      ]"
                      class="pr-2 width-100"
                      v-model="equipmentCreate.no_of_block"
                    ></v-text-field>
                    <v-text-field
                      dense
                      filled
                      color="cyan"
                      label="No. Of Bank"
                      solo
                      flat
                      :rules="[
                        validateRules.maxLength(
                          equipmentCreate.no_of_bank,
                          'No. Of Bank',
                          100
                        ),
                      ]"
                      class="pl-2 width-100"
                      v-model="equipmentCreate.no_of_bank"
                    ></v-text-field>
                  </div>
                  <div class="d-flex">
                    <v-text-field
                      dense
                      filled
                      color="cyan"
                      label="No. Of PM"
                      solo
                      flat
                      :rules="[
                        validateRules.maxLength(
                          equipmentCreate.no_of_pm,
                          'No. Of PM',
                          100
                        ),
                      ]"
                      class="pr-2 width-100"
                      v-model="equipmentCreate.no_of_pm"
                    ></v-text-field>
                    <DatePicker
                      :defaultDate.sync="equipmentCreate.manufacturing_date"
                      :nullable.sync="!equipmentCreate.manufacturing_date"
                      v-model="equipmentCreate.manufacturing_date"
                      placeholder="Manufacturing Date"
                      solo
                      customClass="pl-2 width-100"
                    ></DatePicker>
                  </div>
                </v-col>
              </v-row>
              <!-- </perfect-scrollbar> -->
            </v-col>
            <v-col
              class="custom-sidebar-container grey lighten-3"
              cols="12"
              md="3"
              sm="3"
            >
              <template v-for="(fieldInfo, indx) in fieldDescriptions">
                <div
                  v-if="fieldInfo.field == currentActiveField"
                  :key="indx"
                  class="help-sidebar"
                >
                  <div class="help-sidebar-header">
                    <h3 v-if="fieldInfo.title" class="section-header-title">
                      {{ fieldInfo.title }}
                    </h3>
                  </div>
                  <p
                    v-if="fieldInfo.description"
                    class="help-sidebar-description"
                  >
                    {{ fieldInfo.description }}
                  </p>
                  <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
                    {{ fieldInfo.footer }}
                  </h5>
                  <p
                    v-if="fieldInfo.footer_content"
                    class="help-sidebar-footer-text"
                  >
                    {{ fieldInfo.footer_content }}
                  </p>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <ManageBrand
        :dialog.sync="manageBrandDialog"
        :brand.sync="brandList"
        v-on:close-dialog="manageBrandDialog = false"
        v-on:get-product-brand="getOptions"
      ></ManageBrand>
      <ManageManufacturer
        :dialog.sync="manageManufacturerDialog"
        :manufacturer.sync="manufacturerList"
        v-on:close-dialog="manageManufacturerDialog = false"
        v-on:get-product-manufacturer="getOptions"
      ></ManageManufacturer>
      <ManageCategory
        :dialog.sync="manageCategoryDialog"
        :category.sync="categoryList"
        v-on:close-dialog="manageCategoryDialog = false"
        v-on:get-product-category="getOptions"
      ></ManageCategory>
    </template>
    <template v-slot:barcode-setting>
      <BarcodeSetting
        endpoint="equipment"
        :barcodeDialog="barcodeDialog"
        :dialogWidth="600"
        :barcodeSetting.sync="barcodeSetting"
        v-on:update:barcode-setting="getOptions"
        v-on:close:barcode-dialog="barcodeDialog = false"
      ></BarcodeSetting>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import BarcodeSetting from "@/view/pages/partials/Barcode-Setting.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  CLEAR_ERROR,
  POST,
  GET,
  DELETE,
  QUERY,
} from "@/core/services/store/request.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate";
import DatePicker from "@/view/pages/partials/Datepicker";
import WarrantyMixin from "@/core/lib//warranty/warranty.mixin";
import ManageBrand from "@/view/pages/partials/Manage-Product-Brand.vue";
import ManageManufacturer from "@/view/pages/partials/Manage-Product-Manufacturer.vue";
import ManageCategory from "@/view/pages/partials/Manage-Product-Category.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";

export default {
  mixins: [CommonMixin, ValidationMixin, WarrantyMixin],
  name: "equipment-create",
  title: "Create Equipment",
  data() {
    return {
      serverItemList: [],
      serverSearch: null,
      serverSearchLoading: true,
      pageLoading: true,
      barcodeDialog: false,
      manageBrandDialog: false,
      manageManufacturerDialog: false,
      manageCategoryDialog: false,
      brandList: new Array(),
      categoryList: new Array(),
      manufacturerList: new Array(),
      supplierList: new Array(),
      barcodeSetting: new Object(),
      taxExemptionList: new Array(),
      attachment: new Array(),
      taxList: new Array(),
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
      warrantyActive: false,
      equipmentCreated: false,
      equipmentId: null,
      searchTimeout: null,
      searchTimeoutLimit: 500,
      serviceFormList: [],
      equipmentObject: new Object(),
      equipmentCreate: new Object({
        show_name: true,
        product: null,
        name: null,
        description: null,
        reference: null,
        serial_number: null,
        part_number: null,
        barcode: null,
        product_type: "equipment",
        service_form: null,
        charges: null,
        taxable: false,
        tax: null,
        supplier: null,
        brand: null,
        manufacturer: null,
        category: null,
        pm_one_serial_number: null,
        md_one_serial_number: null,
        manufacturing_date: null,
        control_version: null,
        system_version: null,
        rectifier_dsp_firmware: null,
        inverter_dsp_firmware: null,
        module_eprom_version: null,
        hardware_version: null,
        battery_bank: null,
        battery_model: null,
        no_of_block: null,
        no_of_bank: null,
        no_of_pm: null,
        tax_exemption: null,
        product_images: [],
        warranty: [
          {
            id: null,
            value: null,
            field: null,
          },
        ],
      }),
    };
  },
  watch: {
    "equipmentCreate.product"(param) {
      if (param && this.lodash.isEmpty(param) === false) {
        this.equipmentCreate.description = param.description;
        this.equipmentCreate.part_number = param.part_number;
        this.equipmentCreate.serial_number = param.serial_number;
        this.equipmentCreate.supplier = param.supplier;
        const brand = this.lodash.find(this.brandList, { text: param.brand });
        this.equipmentCreate.brand = brand.id;
        this.equipmentCreate.manufacturer = param.manufacturer;
        this.equipmentCreate.category = param.category;
      }
    },
    serverSearch(previousValue, newValue) {
      if (!this.isProduct && previousValue != newValue) {
        this.getLineItems();
      }
    },
    "equipmentCreate.control_version"(param) {
      this.equipmentCreate.rectifier_dsp_firmware = param;
      this.equipmentCreate.module_eprom_version = param;
    },
    "equipmentCreate.system_version"(param) {
      this.equipmentCreate.inverter_dsp_firmware = param;
      this.equipmentCreate.hardware_version = param;
    },
  },
  components: {
    FileTemplate,
    DatePicker,
    BarcodeSetting,
    ManageBrand,
    ManageManufacturer,
    ManageCategory,
    CreateUpdateTemplate,
  },
  methods: {
    pushWarranty() {
      this.equipmentCreate.warranty.push({
        id: null,
        value: null,
        field: null,
      });
    },
    removeWarranty(index, id) {
      const _this = this;
      if (id && id > 0) {
        _this.$store
          .dispatch(DELETE, {
            url: "product/" + _this.productId + "/entity",
            data: {
              type: 1,
              entities: [id],
            },
          })
          .then(() => {
            _this.equipmentCreate.warranty.splice(index, 1);
          })
          .catch((error) => {
            _this.logError(error);
          });
      } else {
        _this.equipmentCreate.warranty.splice(index, 1);
      }
    },
    updateAttachment(param) {
      this.equipmentCreate.product_images = param;
    },
    createWarrantyEntity(equipmentId) {
      const _this = this;
      return new Promise((resolve) => {
        let warrantyFormData = _this.lodash.compact(
          _this.lodash.map(_this.equipmentCreate.warranty, function (param) {
            if (param.field && param.value) {
              return param;
            }
          })
        );

        if (_this.lodash.isEmpty(warrantyFormData) === false) {
          _this.formLoading = true;
          _this.$store
            .dispatch(POST, {
              url: "product/" + equipmentId + "/entity",
              data: {
                type: 1,
                entities: warrantyFormData,
              },
            })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              _this.logError(error);
            })
            .finally(() => {
              _this.formLoading = false;
            });
        } else {
          resolve(true);
        }
      });
    },
    getOptions() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "product/options",
          data: {
            product_type: "equipment",
          },
        })
        .then(({ data }) => {
          _this.taxList = data.tax;
          _this.taxExemptionList = data.taxExemption;
          _this.equipmentCreate.barcode = data.barcode;
          _this.brandList = data.brand;
          _this.categoryList = data.category;
          _this.manufacturerList = data.manufacturer;
          _this.supplierList = data.supplier;
          _this.serviceFormList = data.service_forms;
          _this.barcodeSetting = data.barcode_setting;
          _this.equipmentCreate.description = _this.barcodeSetting.description;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getEquipmentDetail() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "equipment/" + _this.equipmentId,
        })
        .then(({ data }) => {
          _this.equipmentCreate = new Object({
            show_name: data.show_name,
            name: data.name,
            description: data.description,
            reference: data.reference,
            serial_number: data.serial_number,
            part_number: data.part_number,
            product_type: data.product_type,
            service_form: data.service_form,
            charges: data.charges,
            taxable: data.is_taxable,
            supplier: data.supplier ? data.supplier.id : null,
            brand: data.brand ? data.brand.id : null,
            manufacturer: data.manufacturer ? data.manufacturer.id : null,
            category: data.category ? data.category.id : null,
            pm_one_serial_number: data.pm_one_serial_number || null,
            md_one_serial_number: data.md_one_serial_number || null,
            manufacturing_date: data.manufacturing_date || null,
            control_version: data.control_version || null,
            system_version: data.system_version || null,
            rectifier_dsp_firmware: data.rectifier_dsp_firmware || null,
            inverter_dsp_firmware: data.inverter_dsp_firmware || null,
            module_eprom_version: data.module_eprom_version || null,
            hardware_version: data.hardware_version || null,
            battery_bank: data.battery_bank || null,
            battery_model: data.battery_model || null,
            no_of_block: data.no_of_block || null,
            no_of_bank: data.no_of_bank || null,
            no_of_pm: data.no_of_pm || null,
            tax: _this.lodash.isEmpty(data.tax) == false ? data.tax.id : null,
            tax_exemption:
              _this.lodash.isEmpty(data.tax_exemption) == false
                ? data.tax_exemption.id
                : null,
            product_images: [],
            warranty:
              _this.lodash.isEmpty(data.warranty_data) === false
                ? data.warranty_data.map((row) => {
                    return {
                      id: row.id,
                      value: row.value,
                      min_value: row.min_value,
                      max_value: row.max_value,
                      field: row.field,
                    };
                  })
                : [
                    {
                      id: null,
                      value: null,
                      field: null,
                    },
                  ],
          });

          // _this.attachment = data.product_images;

          if (_this.lodash.isEmpty(data.warranty_data) === false) {
            _this.warrantyActive = true;
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    async updateOrCreate() {
      const _this = this;

      const validateStatus = _this.$refs.equipmentForm.validate();

      const formErrors = _this.validateForm(_this.$refs.equipmentForm);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      _this.formLoading = true;

      _this.$store.dispatch(CLEAR_ERROR, {});

      let product = _this.equipmentCreate.product;
      if (product && product.id) {
        product = product.id;
      }

      let formData = new Object({
        product: product || null,
        name: _this.equipmentCreate.name || null,
        description: _this.equipmentCreate.description || null,
        serial_number: _this.equipmentCreate.serial_number || null,
        part_number: _this.equipmentCreate.part_number || null,
        service_form: _this.equipmentCreate.service_form || null,
        reference: _this.equipmentCreate.reference || null,
        product_type: _this.equipmentCreate.product_type || null,
        supplier: _this.equipmentCreate.supplier || null,
        brand: _this.equipmentCreate.brand || null,
        manufacturer: _this.equipmentCreate.manufacturer || null,
        category: _this.equipmentCreate.category || null,
        charges: _this.equipmentCreate.charges || null,
        pm_one_serial_number:
          _this.equipmentCreate.pm_one_serial_number || null,
        md_one_serial_number:
          _this.equipmentCreate.md_one_serial_number || null,
        manufacturing_date: _this.equipmentCreate.manufacturing_date || null,
        control_version: _this.equipmentCreate.control_version || null,
        system_version: _this.equipmentCreate.system_version || null,
        rectifier_dsp_firmware:
          _this.equipmentCreate.rectifier_dsp_firmware || null,
        inverter_dsp_firmware:
          _this.equipmentCreate.inverter_dsp_firmware || null,
        module_eprom_version:
          _this.equipmentCreate.module_eprom_version || null,
        hardware_version: _this.equipmentCreate.hardware_version || null,
        battery_bank: _this.equipmentCreate.battery_bank || null,
        battery_model: _this.equipmentCreate.battery_model || null,
        no_of_block: _this.equipmentCreate.no_of_block || null,
        no_of_bank: _this.equipmentCreate.no_of_bank || null,
        no_of_pm: _this.equipmentCreate.no_of_pm || null,
        taxable: _this.equipmentCreate.taxable,
        tax: _this.equipmentCreate.tax || null,
        show_name: _this.equipmentCreate.show_name || false,
        sellable: 1,
        confirmed: 1,
        tax_exemption: _this.equipmentCreate.tax_exemption || null,
        images: _this.equipmentCreate.product_images || [],
      });

      try {
        _this.equipmentObject = await _this.$store.dispatch(POST, {
          url: "equipment",
          data: formData,
        });
        if (_this.lodash.isEmpty(_this.equipmentObject) === false) {
          _this.equipmentId = _this.lodash.toSafeInteger(
            _this.equipmentObject.data.id
          );
          if (_this.equipmentId > 0) {
            await _this.createWarrantyEntity(_this.equipmentId);
          }

          _this.$router.push(
            _this.getDefaultRoute("equipment.detail", {
              params: {
                id: _this.equipmentId,
              },
            })
          );
        }
      } catch (error) {
        _this.logError(error);
      }

      _this.formLoading = false;
    },
    getLineItems() {
      const _this = this;
      const search = _this.serverSearch ?? null;
      if (!search && _this.serverItemList.length > 0) {
        return false;
      }
      const products =
        _this.lodash.toSafeInteger(_this.$route.query.product) ?? null;
      _this.serverSearchLoading = true;
      _this.serverItemList = [];
      clearTimeout(_this.searchTimeout);
      _this.searchTimeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "product/get-line-items",
            data: {
              search,
              products,
              purchaseorder: 1,
            },
          })
          .then(({ data }) => {
            _this.serverItemList = data;
            if (_this.isProduct && data) {
              const product = _this.lodash.find(data, ["id", products]);
              if (product) {
                _this.equipmentCreate.product = product.id;
                _this.equipmentCreate.description = product.description;
                _this.equipmentCreate.manufacturer = product.manufacturer;
                _this.equipmentCreate.category = product.category;
                _this.equipmentCreate.supplier = product.supplier;
                _this.equipmentCreate.brand = product.brand_id;
                _this.equipmentCreate.part_number = product.part_number;
              }
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.serverSearchLoading = false;
          });
      }, _this.searchTimeoutLimit);
    },
  },
  created() {
    const _this = this;
    _this.equipmentId = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
    if (_this.equipmentId && _this.equipmentId > 0) {
      _this.getEquipmentDetail();
    }
  },
  mounted() {
    const _this = this;
    _this.getOptions();
    _this.getLineItems();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Equipment", route: "equipment" },
      { title: "Create" },
    ]);
  },
  computed: {
    isProduct() {
      return this.lodash.toSafeInteger(this.$route.query.product)
        ? true
        : false;
    },
  },
};
</script>
